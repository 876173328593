.mainContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;   
  /* padding: 2rem; */
}

.form_section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem 0;
  z-index: 10;
  width: 100%;
}

.formCard{
  display: flex;
  min-height: 550px;
  padding: 1rem 0;
  max-width: 500px;
  width: 100%;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  box-shadow: 0 2px 5px rgb(200, 200, 200);
}
.form_wrapper{
  display: flex;
  max-width: 800px;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.step_inner_wrapper{
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.stepper{
  width: 100%;
  height: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  align-self: center !important;
  position: absolute;
  top: 0;
}

.stepper span span {
  background-color: #f9b136 !important;
}

/* .MuiPickersCalendarHeader-label > .MuiTypography-root:nth-of-type(2) {
  display: none;
} */

.nav{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: 1rem;
}

.button{
  background: black;
  color: whitesmoke;
  border: none;
  height: 56px;
  line-height: 56px;
  min-width: 200px;
  padding: 0 1rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-Radius: 5px;
  font-size: 1rem;
  transition: background 300ms;
  font-family:'Sen', Sans-serif, Arial;

  &:hover {
    opacity: 80%;
  }
}

.button:disabled{
  color: lightgray ;
  border-color: lightgray;
  background: transparent ;
  cursor: unset;
}



.step_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;  
  max-width: 800px;
  width: 100%;
}

.success_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;  
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.field_wrapper{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  height: auto;
  justify-content: center;
}

.your_address{
  width: 200px;
  margin-bottom: 1rem;
}

.personal_info{
  width: 300px;
  justify-self: center;
}
.you_reading{
  width: 300px;
  margin-left: 1rem;
  display: none;
}

.subscribe_title{
  color: var(--color-dark);
  margin-bottom: 2rem;
  width: 100%;
}

.subscribe_subtitle{
  color: var(--color-dark);
  margin-bottom: 0;
  width: 100%;
}
.subscribe_success_subtitle{
  color: var(--color-dark);
  margin-bottom: 0;
  width: 100%;
}

.subscribe_input_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0
}

.subscribe_input{
  width: 300px;
  margin: 0.5rem !important;
}

.ebook{
  width: 100%;
  max-width: 500px;
  z-index: 2;
}

.reference_section{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 150px;
  width: 100%;
  max-width: 1100px;
}

.carousel_container {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  border-color: #4b5563;
  width: 100%;
  height: 100px;  
  mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}

.reference_item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; 
  opacity: 0.8;  
  border-radius: 20%;
}

.reference_item > img {
  height: 65%;
  width: 120%; 
  border-radius: 10%;
}

.carousel_track {
  display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    gap: 16px;
    width: 200%;  
    height: max-content;
    animation: slide 20s linear infinite;
    /* &:hover {
        animation-play-state: paused;
    } */
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

/* -- Mobile landscape */
@media (min-width: 480px) {
  .carousel_container{
    height: 150px;  
  }
  .reference_item > img { 
    width: 100%; 
  }
  .ebook{
    width: 90%;
    border-radius: 2rem;
  }
  .formCard{
    border-radius: 2rem;
    width: 90%;
    margin-top: 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
  }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
  .reference_section{
    height: 250px;
  }
  .formCard{
    width: 80%;  
    max-width: unset;
  }
  .carousel_track{
    height: 200px;
  }
  .reference_item > img {
    height: 140px;
    width: 150px; 
  }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
  .form_section{
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }  
  .formCard{
    max-width: 600px;
    margin-right: -2rem;
    padding: 1rem 2rem;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {

  .formCard{
    height: 550px;
    max-width: 800px;
    width: 70%;
    align-items: left;
    text-align: left;
   
  }
  .form_wrapper{
    margin-left: 2rem;
  }

  .not_required{
    margin-bottom: auto;
  }
  
  .step_inner_wrapper{
    width: 50%;
    height: 100%;
    min-height: 416px;
    display: flex;
    align-items:  flex-start;
  }  
    
  .step_wrapper{
    flex-direction: row;
    align-items: flex-start;  
    width: 90%;
    min-height: 416px;
  }
  
  .success_wrapper{
    margin-right: 2rem;
    width: 80%;
  }
  
  .field_wrapper{
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-start;
    width: 50%;
    justify-content: flex-end;
  }
  
  .your_address{
    margin-left: 4rem;
    margin-bottom: unset;
    height: 200px;
  } 
  
  .you_reading{
    display: block;
    margin-top: 3rem;
  }
    
  .subscribe_subtitle{
    width: 80%;
  }
  
  .subscribe_input_wrapper{
    align-items: flex-start;
  }
  
  .ebook{
    margin-left: -2rem;
  }
  
  
  .carousel_container {
    height: 200px;  
  }   
  
  .reference_item > img {
    height: 80%;
    width: 80%;
  }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
  
}

/* -- FullHD */
@media (min-width: 1921px) {
}