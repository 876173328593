:root {
  --primary-main: #000000;
  --primary-dark: #1f1c16;
  --primary-light: #70634f;
  --primary-contrast-text: #ffffff;
  --primary-text-primary: #f0f0f0;
  --primary-text-secondary: #f5f5f5;
  --primary-text-content: #607095;
  --primary-background-primary: #000000;
  --primary-background-secondary: #f9b136;
  --primary-background-content: #fdfdfd;
  --primary-background-cover: #607095;
  --primary-success: #157145;
  --primary-error: #e6332a;
  --primary-info: #4a7b9d;
  --font-main: 'Raleway', sans-serif;
  --font-secondary: 'Raleway', sans-serif;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

::-webkit-scrollbar {
  width: 0.3rem;
}
  
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-background-cover);
  border-radius: 1rem;
}