.widget {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1.25rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 350px;
  z-index: 100;
  will-change: transform;
}



.widget:hover {
  transform: translateY(-3px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.imageContainer {
  position: relative;
  width: 65px;
  height: 65px;
  margin-right: 1.25rem;
  flex-shrink: 0;
}

.imageContainer::after {
  content: '';
  position: absolute;
  inset: -3px;
  border-radius: 50%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.3)
  );
  z-index: -1;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
}

.widget:hover .profileImage {
  transform: scale(1.05);
}

.info {
  flex: 1;
  min-width: 0;
}

.username {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 0.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.email, .phone {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease;
}

.widget:hover .email,
.widget:hover .phone {
  color: rgba(255, 255, 255, 0.9);
} 

@media (max-width: 768px) {
  .widget {
    left: 0rem;
  }
}

@media (max-width: 480px) {
  .widget {
    right: 0rem;
  }
}



