.sidebar_container {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  padding: 16px;
  background-color: #f5f5f5;
  max-height: 200px;
  position: sticky;
  top: 5rem;
}

.sidebar_top {
  padding: 0 1rem;
}

.link_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.link_container > a {
  max-height: 2.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress {
  padding: 3rem;
  display: table !important;
  margin: 0 auto;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
